<template>
  <div class="app-box">
    <!-- <div class="search-box">
      <input type="text" placeholder="请输入货品名称或客户名称" class="search-txt" v-model="selectObject.keyWord" @input="inputEvent">
    </div> -->
    <x-scroll-view>
      <table v-if="xhrData.length>0" class="data-table double-table" style="width:8.6rem;">
        <colgroup>
          <col style="width:1.6rem;">
          <col style="width:1.6rem;">
          <col style="width:1.6rem;">
          <col style="width:0.8rem;">
          <col style="width:0.6rem;">
          <col style="width:0.6rem;">
          <col style="width:0.6rem;">
          <col style="width:1.2rem;">
        </colgroup>
        <thead>
          <tr>
            <th>
              <span>客户名称</span>
            </th>
            <th>
              <span>货品名称</span>
            </th>
            <th scope="col">
              <span>规格</span>
            </th>
            <th scope="col">
              <span>价格(元)</span>
            </th>
            <th scope="col">
              <span>客户ID</span>
            </th>
            <th scope="col">
              <span>货品ID</span>
            </th>
            <th scope="col">
              <span>单位</span>
            </th>
            <th scope="col">
              <span>有效期结束时间</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item,index) in xhrData" :key="index">
            <th scope="row">{{item.companyName}}</th>
            <td>{{item.instrumentName}}</td>
            <td>{{item.specification}}</td>
            <td>{{item.price|formatNum}}</td>
            <td>{{item.companyId}}</td>
            <td>{{item.instrumentId}}</td>
            <td>{{item.unit}}</td>
            <td>{{item.validEndDate}}</td>
          </tr>
        </tbody>
      </table>
      <x-no-data-bg v-if="ajaxEnd&&xhrData.length<=0"></x-no-data-bg>
    </x-scroll-view>
  </div>
</template>

<script>
import scrollView from "@/components/scrollView";
import { listInstrumentSpecialPrice } from "@/api/productInquiry";
import noDataBg from "@/components/noDataBg";
import { formatNum } from "@/script/tools";

export default {
  data () {
    return {
      timer: null,
      xhrData: [],
      ajaxEnd: false,
      selectObject: {
        keyWord: null
      }
    };
  },
  mixins: [formatNum],
  created () {
    document.title = "客户特殊价格";
    this.getDataList();
  },
  methods: {
    inputEvent () {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.ajaxEnd = false;
        this.getDataList();
      }, 300);
    },
    getDataList () {
      listInstrumentSpecialPrice(this.selectObject).then(xhr => {
        this.xhrData = xhr.data.list;
        this.ajaxEnd = true;
      });
    }
  },
  watch: {
    $route (to, from) {
      if (to.meta.index <= from.meta.index) {
        document.title = "客户特殊价格";
      }
    }
  },
  components: {
    [scrollView.name]: scrollView,
    [noDataBg.name]: noDataBg
  }
};
</script>

<style scoped>
.search-txt {
  flex: 1;
  margin: auto 0.15rem;
}
</style>
